.toggle-switch {
  position: relative;
  width: 40px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.2s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 22px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: '';
      text-transform: uppercase;
      // padding-left: 10px;
      background-color: #687ce3a8;
      color: #fff;
    }
  }
  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: '';
    text-transform: uppercase;
    // padding-right: 10px;
    background-color: #e6e6e6;
    // color: #fff;
    // text-align: right;
  }
  &-switch {
    display: block;
    width: 18px;
    margin: 2px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 18px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.2s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      margin: 2px;
      right: 0px;
    }
  }
}

.doc-explorer-title,
.graphiql-doc-explorer-title,
.graphiql-history-header {
  font-size: 14px !important;
}
._hj_feedback_container {
  display: none !important;
}
