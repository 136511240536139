.btns {
  border: 1px solid #e5e7eb;
}
.container-gql {
  .graphiql-container * {
    box-sizing: content-box;
  }
  .graphiql-editor {
  }
  .middle-container {
    border-left: 1px solid #e5e7eb;
    border-right: 1px solid #e5e7eb;
  }
  .result-window {
    // border-right: 1px solid #e5e7eb;
    width: 45%;
    .cm-s-graphiql {
      // background-color: #f3f3f3;
    }
  }
  .button-container {
    border-top: 1px solid #e5e7eb;
    border-bottom: 1px solid #e5e7eb;
    button {
      padding: 0.25rem 0.5rem;
      &:hover {
        font-weight: bold;
      }
      &.active {
        font-weight: bold;
      }
    }
  }
}


.btn.rounded-none {
  border-radius: 0%;
}
