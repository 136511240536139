.info-card {
  background-image: url('assets/card.svg');
}

.credit-card {
  background: url('assets/cc.svg'), linear-gradient(#0400DD1A, #0400DD1A),
    radial-gradient(#962eff, #516dff);
}
.upgrade-modal{
  background-image: url('assets/upgrade-bg.svg');
  margin: -20px -24px 30px;
}
