.md-display code {
  font-weight: 600;
  background-color: #e3e3e3;
  padding: 2px 4px;
}

.md-display pre {
  width: 100%;
  overflow: auto;
  background-color: #e3e3e3;
  padding: 2px 4px;
}