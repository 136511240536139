.query-history-list {
  table {
    tr {
      border-bottom: 2px solid #efefef;
    }
    th {
      border-bottom: 2px solid #efefef;
      opacity: 0.75;
    }
    td,
    th {
      padding-left: 0;
      padding-right: 1rem;
      padding-bottom: 0.6rem;
      padding-top: 0.6rem;
      font-weight: 500 !important;
      font-size: 0.8rem !important;
    }
    th {
      font-weight: 700 !important;
      color: #000 !important;
    }
  }
  .tooltip:before {
    font-weight: 400 !important;
    font-size: 0.8rem !important;
  }
}
