.banner {
  h1 {
    background: -o-linear-gradient(
      right,
      rgba(40, 26, 240, 1),
      rgba(150, 46, 255, 1)
    );
    background: -moz-linear-gradient(
      right,
      rgba(40, 26, 240, 1),
      rgba(150, 46, 255, 1)
    );
    background: linear-gradient(
      to right,
      rgba(40, 26, 240, 1),
      rgba(150, 46, 255, 1)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.bglogo {
  background-image: url('./LOGO-Shape.png');
  background-repeat: repeat-x;
  background-size: contain;
}
