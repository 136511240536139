.api-table {
  table {
    width: 100%;
  }
  thead {
    th:first-child {
      text-align: left;
    }
    th {
      font-weight: normal;
    }
  }
  tbody {
    td {
      text-align: center;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    td:first-child {
      text-align: left;
      width: 400px;
    }
  }
}
