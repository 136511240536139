ul.overlap-avatar {
  img {
    border-radius: 50%;
    border: 1px solid white;
  }
  min-height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;

  li {
    margin-right: -1em;
    z-index: 1;
    list-style-type: none;
  }
}
