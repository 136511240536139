.btn {
  @apply h-9;
  @apply min-h-fit;
  @apply rounded-md;
  @apply no-animation;
}

.btn-sm {
  @apply h-7;
  font-size: 0.86rem;
}

.btn-xs {
  @apply h-6;
  font-size: 0.65rem;
}

.btn-lg {
  @apply h-10;
}

.input,
.select {
  @apply h-9;
  @apply min-h-fit;
  @apply leading-normal;
}

.input-md,
.select-md {
  @apply h-8;
}

.input-sm,
.select-sm,
.select-sm option {
  @apply h-7;
}

.input-xs,
.select-xs {
  @apply h-6;
}

/* toggle */
.toggle {
  @apply h-5 w-11;
}
.toggle-sm {
  @apply h-4 w-7;
}
.toggle-xs {
  @apply h-3 w-6;
}

.rounded {
  border-radius: 0.5rem;
}

.select-disabled,
.select[disabled] {
  @apply border-opacity-10;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
}

.no-outline {
  @apply focus:outline-none;
  @apply active:outline-none;
}
