ul.overlap-avatars {
  img {
    border-radius: 50%;
    border: 1px solid white;
  }

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;

  li {
    margin-right: -0.5em;
    z-index: 1;
    list-style-type: none;
  }
}
