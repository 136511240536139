.readonly {
  .cm-editor {
    height: 100%;
    background: #f3f3f3;

    .cm-activeLine {
      background: #f3f3f3;
    }
    .cm-activeLineGutter {
      background-color: unset;
    }
    .cm-cursorLayer,
    .cm-selectionLayer {
      visibility: hidden;
    }
  }
}
