.setupMarkdown {
  code {
    color: #ff3838;
  }
  pre {
    padding: 0.75rem;
    background-color: #fff;
    font-size: 14px;
    white-space: pre-wrap;
  }
  > * {
    margin-top: 12px;
  }
  table,
  th,
  td {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-collapse: collapse;
  }
  td {
    padding: 0.25rem;
  }
}
