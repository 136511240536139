.container {
  margin: 0 auto;
  width: 1200px;
}
body {
  margin: 0;
}

#wrapper {
  padding: 1rem;
  height: calc(100vh - 134px);
  text-align: left;
}

#wrapper .title {
  display:  none;
}