.blockArrow:after {
  border-right: 0.75rem solid;
  border-top: 0.75rem solid transparent;
  border-bottom: 0.75rem solid transparent;
  display: inline-block;
  content: '';
  position: absolute;
  left: -0.75rem;
}
.blockArrow {
  display: inline-block;
  position: relative;
}
