.query-history {
  table {
    th {
      border-bottom: 2px solid #efefef;
      opacity: 0.5;
      font-weight: 400;
      font-size: 0.8rem;
    }
    td,
    th {
      padding-left: 0;
      padding-right: 1rem;
      padding-bottom: 0.6rem;
      padding-top: 0.6rem;
    }
  }
}
